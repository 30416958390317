import { AxiosError } from 'axios';
import { getApiErrorMessage, getApiErrorStatus, ReactChildren, WithRequired, getApiErrors } from '../utils';
import { ApiErrors } from '../hooks/validation/useValidation';
import { ApiResultError } from '../clientApi/ClientApiTypes';

export type CustomErrorMessagesConfig = {
    default?: string;
    ranges?: Array<{ from: number; to: number; message: string }>;
    [key: number]: string;
};

export const DEFAULT_ERROR_MESSAGE =
    'The server encountered an internal error or misconfiguration and was unable to process your request.';

type DefaultCustomErrorMessagesConfig = WithRequired<CustomErrorMessagesConfig, 'default'>;

const DefaultConfig: DefaultCustomErrorMessagesConfig = {
    default: DEFAULT_ERROR_MESSAGE,
    ranges: [{ from: 400, to: 499, message: 'The server was unable to process your request.' }],
    403: "You don't have permission to access this resource.",
    404: 'The content you requested was not found.',
    503: 'The server is temporarily busy. Please try again later.',
};

type CustomErrorMessageProps = {
    code?: number;
    responseMessage?: string;
    className?: string;
    messageConfig?: CustomErrorMessagesConfig;
    showConfigMessage?: boolean;
};

const prepareConfig = (config?: CustomErrorMessagesConfig): DefaultCustomErrorMessagesConfig => ({
    ...DefaultConfig,
    ...config,
    default: config?.default ?? DefaultConfig.default,
    ranges: [...(config?.ranges ?? []), ...(DefaultConfig.ranges ?? [])],
});

const getErrorMessage = ({
    code,
    messageConfig,
    responseMessage,
    showConfigMessage,
}: {
    code?: number;
    messageConfig?: CustomErrorMessagesConfig;
    responseMessage?: string;
    showConfigMessage?: boolean;
}) => {
    let message = responseMessage;
    if (!message || showConfigMessage) {
        const config = prepareConfig(messageConfig);

        message = config.default;
        if (code !== undefined) {
            const specificMessage = config[code] ?? config.ranges?.find((r) => r.from <= code && r.to >= code)?.message;
            message = specificMessage ?? message;
        }
    }
    return message;
};

function CustomErrorMessage(props: CustomErrorMessageProps) {
    return <div className={props.className}>{getErrorMessage(props)}</div>;
}

type Props = {
    children?: ReactChildren;
    config?: CustomErrorMessagesConfig;
    error?: unknown | null;
    errorMessageClassName?: string;
    showConfigMessage?: boolean;
    customErrorComponent?: (errorMessage: string, errors: ApiErrors<string>) => ReactChildren;
};

export default function ErrorDisplay({
    error,
    children,
    config,
    errorMessageClassName,
    showConfigMessage = false,
    customErrorComponent,
}: Props) {
    if (!error) {
        return <>{children}</>;
    }

    const responseMessage = getApiErrorMessage(error);
    const errors = getApiErrors(error as AxiosError<ApiResultError>);

    if (customErrorComponent) {
        let errorMessage: string;

        if (error instanceof AxiosError) {
            const code = getApiErrorStatus(error);
            errorMessage = getErrorMessage({ code, messageConfig: config, responseMessage, showConfigMessage });
        } else {
            errorMessage = getErrorMessage({ messageConfig: config, responseMessage, showConfigMessage });
        }

        return <>{customErrorComponent(errorMessage, errors)}</>;
    }

    if (error instanceof AxiosError) {
        const code = getApiErrorStatus(error);

        return (
            <CustomErrorMessage
                code={code}
                messageConfig={config}
                className={errorMessageClassName}
                responseMessage={responseMessage}
                showConfigMessage={showConfigMessage}
            />
        );
    }

    return (
        <CustomErrorMessage
            messageConfig={config}
            className={errorMessageClassName}
            responseMessage={responseMessage}
            showConfigMessage={showConfigMessage}
        />
    );
}
