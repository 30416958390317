import { classNames } from '../../utils';

interface TextParams {
    className?: string;
    height?: string;
    width?: string;
    margin?: string;
}

/** Display a loading Text animation */
export default function SkeletonText({ className, height = 'h-4', width = 'w-full', margin = 'm-1' }: TextParams) {
    return (
        <div
            role="status"
            className={classNames(
                'flex-2 dark:bg-skeleton-50 animate-pulse rounded-full bg-skeleton-10',
                className,
                height,
                width,
                margin
            )}
        />
    );
}
