import { TD } from '../table/Table';
import SkeletonText from './SkeletonText';

type Props = {
    textClassName?: string;
};

/** Create a table cell loading animation. You probably want SkeletonRow */
export default function SkeletonCell({ textClassName }: Props) {
    return (
        <TD>
            <SkeletonText className={textClassName} />
        </TD>
    );
}
